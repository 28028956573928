import './Footer.css';
import heartIcon from './heart.png';
import { useTranslation } from 'react-i18next';

const openCrispChat = (window as any).openCrispChat;

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className='footer'>
      <div>
        <span className='need-help-text'>{t('NEED_HELP')} </span>
        <button className='need-help button-modified' aria-label={t('CONTACT_US')} onClick={() => {
          openCrispChat()
        }}>{t('CONTACT_US')}</button>
      </div>
      <div>{t('MADE_WITH')} <img className='heart-icon' src={heartIcon} alt={t('LOVE')} /> {t('BY')} Televeda</div>
    </footer>
  )
};

export default Footer
