import { useEffect, useMemo, useState } from 'react';
import Button from '../../components/button/Button';
import { useLocation } from 'react-router-dom';
import ConfettiExplosion from 'react-confetti-explosion';
import { deleteCookie, getCookie } from '../../utils/cookie-typescript-utils';
import { useTranslation } from 'react-i18next';

const VerificationCompleteScreen = () => {
  const { t } = useTranslation();
  
  const roles = useLocation().state?.roles || [];

  const [redirectCooldown, setRedirectCooldown] = useState<number>(5);

  const [goToParam, setGoToParam] = useState('');

  const redirectUser = () => {
    const currentURL = new URL(window.location.href);
    window.location.href = currentURL.origin + "/" + goToParam;
  }

console.log('roles', roles)

  const { adminPermissions, hostPermissions, managerPermissions } = useMemo(() => {
    const adminPermissions = roles.includes('Admin');
    const hostPermissions = roles.includes('Host');
    const managerPermissions = roles.includes('Manager');

    return { adminPermissions, hostPermissions, managerPermissions }
  }, [roles]);

  useEffect(() => {
    if(adminPermissions || hostPermissions || managerPermissions) {
      setRedirectCooldown(10);
    }
  }, [roles]);

  useEffect(()=>{

    let timeout: any = null;

    if(redirectCooldown && redirectCooldown>0){
      timeout = setTimeout(()=>{
        setRedirectCooldown(redirectCooldown-1);
      }, 1000)  
    }
    else {
      redirectUser();
    }

    return () => {
      if(timeout) {
        clearTimeout(timeout);
      }
    }

  }, [redirectCooldown]);

  useEffect(()=> {
    const goTo = getCookie('goTo');
    if(goTo){
      setGoToParam(goTo);
      deleteCookie("goTo");
    }
  }, [])

  return (
    <div className='body-container'>
        <ConfettiExplosion/>
        <main className='content-container-standalone'>
            <div className='text-container'>
              <span className='text-big'>{t('VERIFICATION_COMPLETE')}</span>
              <span className='text-medium'>{`${t('VERIFICATION_COMPLETE_SCREEN_TEXT_ONE')} ${goToParam ? t('DESTIONATION') : t('CLASS_SCHEDULE')} ${t('IN')} [${redirectCooldown}]`}</span>
            </div>
            <Button title={goToParam ? t('GO_TO_DESTINATION') : t('GO_TO_CLASS_SCHEDULE')} onClick={redirectUser}/> 
            {(adminPermissions || hostPermissions) && <Button secondary title={t('GO_TO_HOST')} onClick={() => window.location.href = "/host/select-class"}/>}
            {(adminPermissions || managerPermissions || hostPermissions) && <Button secondary title={t('GO_TO_ADMIN')} onClick={() => window.location.href = "/admin"}/>}
        </main>
    </div>
  )
};

export default VerificationCompleteScreen