import React, { useMemo } from "react";
import './InputField.css';
import { RegisterOptions, FieldErrors, UseFormRegister, FieldValues } from "react-hook-form";
interface InputFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    register: UseFormRegister<FieldValues>;
    errors: FieldErrors<FieldValues>;
    options?: RegisterOptions<FieldValues, string> | undefined;
    isErrorThrown?: boolean;
    isOptional?: boolean;
    type?: "text" | "textarea";
    disabled?: boolean;
    autoFocus?: boolean;
    onPhoneChange?: (phone: string) => void;
}

const InputField = (props: InputFieldProps) => {
    const INVALID_EMAIL_MESSAGE = "invalid email address";
    
    const error = useMemo(()=>{
      if(props.errors[props.name]?.type==='required'){
        return 'Required'
      }
      return props.errors[props.name]?.message as string;
    }, [props.errors[props.name]?.message, props.errors[props.name]])
    
  return (
    <>
      <div className={'input-wrapper'}>
        {(!props.type || props.type==='text') && 
          <input
            autoFocus={props.autoFocus || false} 
            disabled={props.disabled || false}
            className={!!props.errors[props.name] ? 'input-field input-field-required' : 'input-field'} 
            defaultValue={props.defaultValue} 
            {...props.register(props.name, {...props.options})} 
            placeholder={props.placeholder}
            aria-invalid={!!props.errors[props.name]}
          />
        }
        
        {props.type==='textarea' && 
          <textarea 
            autoFocus={props.autoFocus || false} 
            placeholder={props.placeholder} 
            disabled={props.disabled || false}
            className={!!props.errors[props.name] ? 'textarea input-field-required' : 'textarea'} 
            {...props.register(props.name, 
            {...props.options})}
          />
        }
        
        { error && <span className="error-text" role="alert" aria-live="assertive">{error}</span> }
      </div>
    </>
  )
};

export default InputField;
