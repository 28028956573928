import { useEffect, useState } from 'react';
import './NavigationBar.css';
import televedaLogo from './televeda-logo 4.svg';
import { loadAudioEyeScript } from './audioeyeScript';

interface NavigationBarProps {
  communityName: string | null;
  communityLogo: string | null;
}

const NavigationBar = ({ communityName, communityLogo }: NavigationBarProps) => {

  const [logo, setLogo] = useState<string | null>(null);
  const [logoName, setLogoName] = useState('navigation-logo');

  const setPrimaryColor = (hexCode: string) => {
    window.localStorage.setItem('primaryColor', hexCode);
    document.body.style.setProperty('--primary', `#${hexCode}`)
    document.body.style.setProperty('--primary-lighter', `#${hexCode}66`)
    document.body.style.setProperty('--primary-light', `#${hexCode}33`)
  }

  const checkReferralId = async () => {
    let url = new URL(window.location.href);

    const code = url.searchParams.get('ref');

    if (!code) {
      setLogo(televedaLogo);
      setLogoName('navigation-logo');
    }
  }

  useEffect(() => {
    if(communityLogo) {
      setLogo(communityLogo);
      setLogoName('navigation-logo-community');
    }
  }, [communityLogo])

  useEffect(()=>{
    let primaryColor = window.localStorage.getItem('primaryColor');
    if(primaryColor){
      setPrimaryColor(primaryColor);
    }

    loadAudioEyeScript();
    checkReferralId();
  },[])

  return (
    <nav className={logo ? 'navigation-bar' : 'navigation-bar-no-logo'} aria-label="Main Navigation">
        {logo && <img src={logo} className={logoName} alt="Community Logo" />}
        {communityName && <div className='navigation-community-title text-community-label'>{communityName}</div>}
    </nav>
  );
};

export default NavigationBar
