import { useEffect, useState } from 'react';
import Button from '../../components/button/Button';
import InputField from '../../components/inputField/InputField';
import ProgressBar from '../../components/progressBar/ProgressBar';
import { useLocation, useNavigate } from 'react-router-dom';
import MessageBubble from '../../components/messageBubble/MessageBubble';
import { useForm } from "react-hook-form";
import { SignupAPI } from '../../logic/signupApi/signupApi';
import ErrorMessage from '../../components/errorMessage/ErrorMessage';
import Loader from '../../components/loader/Loader';
import { setCookie } from '../../utils/cookie-typescript-utils';
import { useTranslation } from 'react-i18next';

const EmailScreen = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { register, handleSubmit, formState: { errors } } = useForm();

  const [linkRef, setLinkRef] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const tooltipAppearTime = 15;

  const onSubmit = (data: any) => {
    setIsLoading(true);

    let email = data.email.trim();

    SignupAPI.sendLoginCode({
      whereToSendTheCode: 'email',
      email: email.trim()
    }).then(() => {
      navigate("verification", { state: { email: email } })
      setIsLoading(false);
    }).catch((error: any) => {
      console.log('error while sending login code: ', error);
      setIsLoading(false);

      if (error.opCode) {

        switch (error.opCode) {
          case 'auth/user-not-found':
            console.log("Navigate to registration with email: ", email);
            navigate(`/signup${linkRef ? `?ref=${linkRef}`: ''}`, { state: { email: email } });
            break;

          default:
            break;
        }
      }
      else {
        setErrMsgTxt(error.message);
        setErrMsgVisible(true);
      }
    });
  };

  const [timer, setTimer] = useState(0);

  useEffect(() => {
    if (timer < tooltipAppearTime) {
      setTimeout(() => {
        setTimer(timer + 1);
      }, 1000);
    }
    if (timer === tooltipAppearTime) {
      setIsTooltipVisible(true);
    }
  }, [timer])

  const [errMsgVisible, setErrMsgVisible] = useState(false);
  const [errMsgTxt, setErrMsgTxt] = useState('');

  const email = useLocation().state?.email || null;
  const filledEmail = useLocation().state?.filledEmail || null;
  const phone = useLocation().state?.phone || null;

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const goTo = urlParams.get('goto');
    if(goTo){
      setCookie('goTo', goTo);
    }

    const link = urlParams.get('ref');
    if(link) {
      setLinkRef(link);
    }
  }, []);

  const removeMessageBubble = () => {
    setIsTooltipVisible(false);
  }

  return (
    <div className='body-container'>
      <main className='content-container'>
        {
          errMsgVisible && <ErrorMessage message={errMsgTxt} onClose={() => { setErrMsgVisible(false); }} />
        }
        <div className='text-container'>
          <h1 className='text-big'>{t('WELCOME')}!</h1>
          <span className='text-big'>
            {
              email ? t('IS_YOUR_EMAIL') : t('WHAT_IS_EMAIL')
            }
          </span>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className='form-container'>
          <InputField
            name="email"
            errors={errors}
            register={register}
            autoFocus
            options={{
              required: true,
              pattern: {
                value: /\s*^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\s*$/i,
                message: t('INVALID_EMAIL')
              },
              validate: {
                minLength: (v) => v.length >= 0,
              }
            }}
            placeholder={t('EMAIL_ADDRESS')}
            defaultValue={email || filledEmail}
          />
          {isLoading && <Loader />}
          <Button title={t('CONTINUE')}
          />
        </form>
        <button className='button-modified' onClick={() => navigate(`/login/phone${linkRef ? `?ref=${linkRef}`: ''}`, { state: { email: email, phone } })}>{t('EMAIL_SCREEN_TEXT_FIRST')}</button>
      </main>
      <ProgressBar numberOfSteps={2} currentStep={1} />
      {isTooltipVisible && <MessageBubble removeBubble={removeMessageBubble} title={"Need help?"} content={t('EMAIL_SCREEN_TEXT_SECOND')} />}
      <button 
        className='button-modified'
        onClick={() => navigate(`/signup${linkRef ? `?ref=${linkRef}`: ''}`)}
      >
        {t('REGISTER_INSTEAD')}
      </button>
    </div>
  )
};

export default EmailScreen