import { useEffect, useState } from 'react';
import Button from '../../components/button/Button';
import ProgressBar from '../../components/progressBar/ProgressBar';
import { useLocation, useNavigate } from 'react-router-dom';
import MessageBubble from '../../components/messageBubble/MessageBubble';
import { useForm } from "react-hook-form";
import { ConfirmationResult, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { auth } from '../../FirebaseConfig';
import PhoneInput from 'react-phone-input-2';
import Loader from '../../components/loader/Loader';
import { SignupAPI } from '../../logic/signupApi/signupApi';
import ErrorMessage from '../../components/errorMessage/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { useCountry } from '../../utils/phoneNumberUtils';

declare global { 
  interface Window {
    recaptchaVerifier: RecaptchaVerifier;
    phoneConfirmatioin: ConfirmationResult;
  }
}

const PhoneScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { register, handleSubmit, formState: { errors } } = useForm();

  const [linkRef, setLinkRef] = useState<string | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const [errMsgVisible, setErrMsgVisible] = useState(false);
  const [errMsgTxt, setErrMsgTxt] = useState('');
  
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const tooltipAppearTime = 15;

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    const appVerifier = window.recaptchaVerifier;
        
    await SignupAPI.checkIfPhoneExists(`+${phoneNumber}`).then(() => {
      signInWithPhoneNumber(auth, `+${phoneNumber}`, appVerifier)
      .then((confirmationResult) => {
        setIsLoading(false);
        navigate(`/login/verification${linkRef ? `?ref=${linkRef}`: ''}`, {state: {email: '', phone: `+${phoneNumber}`, phoneConfirmation: JSON.stringify(confirmationResult)}});
      }).catch((error) => {
        console.log("SMS Error: ", error);
        setErrMsgTxt("Something went wrong. Please note that accounts with 2FA enabled can't use phone sign up!");
        setErrMsgVisible(true);
        setIsLoading(false);
      });
    }).catch((error: unknown) => {
      setIsLoading(false);
      navigate(`/signup${linkRef ? `?ref=${linkRef}`: ''}`, { state: { phone: phoneNumber } });
    })

  };

  const [timer, setTimer] = useState(0);

  const [phoneNumber, setPhoneNumber] = useState('');

  const email = useLocation().state?.email || null;
  const filledPhone = useLocation().state?.filledPhone || null;
  const phone = useLocation().state?.phone || null;

  const { country } = useCountry();

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);

    if(phone || filledPhone) {
      setPhoneNumber(phone || filledPhone);
    }

    const link = urlParams.get('ref');
    if(link) {
      setLinkRef(link);
    }
  }, []);

  useEffect(()=>{
    if(timer<tooltipAppearTime){
      setTimeout(()=>{
        setTimer(timer+1);
      }, 1000);
    }
    if(timer===tooltipAppearTime){
      setIsTooltipVisible(true);
    }
  },[timer]);

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', { 'size': 'invisible' }, auth);
  }, []);

  const removeMessageBubble = () => {
    setIsTooltipVisible(false);
  }

  return (
    <div className='body-container'>
        <main className='content-container'>
            {
              errMsgVisible && <ErrorMessage message={errMsgTxt} onClose={() => { setErrMsgVisible(false); }} />
            }
            <div className='text-container'>
                <h1 className='text-big'>{t('WELCOME')}!</h1>
                <span className='text-big'>
                  {
                    phone ? t('PHONE_SCREEN_TEXT_FIRST') : t('PHONE_SCREEN_TEXT_SECOND')
                  }
                </span>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className='form-container'>
              {
                // If country is assigned and there is a phone value in the field the value is removed for some reason
                phone ?
                // if we come to this screen from the registration with a phone number,
                // the country is automatically detected from the phone number,
                // so we can bypass the bug by not fetching the country
                <PhoneInput 
                  value={phoneNumber}
                  inputClass={phoneNumber ? 'phone-input-bold' : 'phone-input-regular'}
                  buttonClass={'phone-button'}
                  onChange={(phone) => {
                  setPhoneNumber(phone)
                }}/>
                :
                // if we come here without a phone number to display,
                // we fetch and assign the country
                // and since there is no value that is going to disappear its okay
                <PhoneInput 
                  country={country}
                  inputClass={phoneNumber ? 'phone-input-bold' : 'phone-input-regular'}
                  buttonClass={'phone-button'}
                  value={phoneNumber}
                  onChange={(phone) => {
                  setPhoneNumber(phone)
                }}/>
              }
              {isLoading && <Loader/>}
              <Button id='recaptcha-container' title={t('CONTINUE')} aria-label={t('CONTINUE')} /> 
            </form>
            <button className='button-modified' aria-label={t('bl')} onClick={()=> navigate(`/login/${linkRef ? `?ref=${linkRef}`: ''}`, {state: {email: email, phone: phoneNumber}})}>{t('PHOHE_SCREEN_TEXT_THIRD')}</button>
            &nbsp;
            <span className='text-small-bold'>{t('PHONE_SCREEN_TEXT_FOURTH')} 833-299-1449</span>
        </main>
        <ProgressBar numberOfSteps={2} currentStep={1}/>
        {isTooltipVisible && <MessageBubble removeBubble={removeMessageBubble} title={t('NEED_HELP')} content={t('PHONE_SCREEN_TEXT_FIFTH')}/>}
        <button className='button-modified' aria-label={t('REGISTER_INSTEAD')} onClick={()=> navigate(`/signup${linkRef ? `?ref=${linkRef}`: ''}`)}>{t('REGISTER_INSTEAD')}</button>
      </div>
  )
};

export default PhoneScreen