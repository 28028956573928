import React from 'react';
import './Checkbox.css';
import { UseFormRegister, FieldValues, FieldErrors, RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface CheckboxProps{
    name: string;
    register: UseFormRegister<FieldValues>;
    errors: FieldErrors<FieldValues>;
    options?: RegisterOptions<FieldValues, string> | undefined;
    children: React.ReactNode;
    value: boolean;
    onChange?: () => void;
}

const Checkbox = (props: CheckboxProps) => {
  const { t } = useTranslation();
  return (
    <div className='checkbox-container'>
      <div className='checkbox-children-container'>
        <input 
        aria-label='checkbox'
        type='checkbox' 
        className='checkbox'
        // checked={props.value} 
        {...props.register(props.name, props.options)} 
        // onChange={()=> props.onChange()}
        />
        {props.children}
      </div>
      {props.errors[props.name] && 
      <div className='error-container'><span className='error-text' role="alert" aria-live="assertive">{t('REQUIRED')}</span></div>}
    </div>
  )
};

export default Checkbox