import React from 'react';
import './Message.css';
import closeIcon from './close.png';

interface MessageProps {
    title: string;
    children: React.ReactNode;
    onClose: () => void;
}

const Message = (props: MessageProps) => {
  return (
    <>
        <div className='message-container'>
            <img className='close-btn' src={closeIcon} onClick={() => props.onClose()}/>
            <span className='text-small-bold'>{props.title}</span>
            {props.children}
        </div>
    </>
  )
};

export default Message