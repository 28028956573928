import { useEffect, useState } from 'react';
import './App.css';
import NavigationBar from './components/navigation/NavigationBar';
import Footer from './components/footer/Footer';
import EmailScreen from './screens/emailScreen/EmailScreen';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import RegistrationScreen from './screens/registrationScreen/RegistrationScreen';
import VerifyCodeScreen from './screens/verifyCodeScreen/VerifyCodeScreen';
import LandlineScreen from './screens/landlineScreen/LandlineScreen';
import VerificationCompleteScreen from './screens/verificationCompleteScreen/VerificationCompleteScreen';
import PhoneScreen from './screens/phoneScreen/PhoneScreen';
import { loadAudioEyeScript } from './components/navigation/audioeyeScript';

function App() {

  useEffect(()=>{
    loadAudioEyeScript();
  },[]);

  const [communityLogo, setCommunityLogo] = useState<string | null>(null);
  const [communityName, setCommunityName] = useState<string | null>(null);

  return (
    <div className="main-container">
      <NavigationBar communityName={communityName} communityLogo={communityLogo} />
      <BrowserRouter>
        <Routes>
          <Route path="login">
            <Route index element={<EmailScreen />} />
            <Route path='phone' element={<PhoneScreen />} />
            <Route path='verification' element={<VerifyCodeScreen />} />
            <Route path='verification-complete' element={<VerificationCompleteScreen />} />
            <Route path='landline' element={<LandlineScreen />} />
          </Route>
          <Route path="signup">
            <Route index element={<RegistrationScreen communityName={communityName}  setCommunityName={setCommunityName} setCommunityLogo={setCommunityLogo} />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>

  );
}

export default App;
